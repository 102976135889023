import React from "react"
import { Link } from "gatsby"

import UserContext from "../context/UserContext"
export default () => (

    <UserContext.Consumer>
      {user => (
        <>
          <h1>Welcome to GO Results {user.isLoggedIn() ? user.name : ""}.</h1>
          <p>
            {user.isLoggedIn() ? (
              <>
                You are logged in, so check your{" "}
                <Link to="/app/home">Home page</Link>
              </>
            ) : (
              <>
                Please <Link to="/app/login">log in</Link>.
              </>
            )}
          </p>
        </>
      )}
    </UserContext.Consumer>  
)
